import { apiRequest } from "../../helpers/api/apiRequest";
import { isEmpty } from "helpers/utils";
import { STOP_ACTION, START_ACTION } from "store/constants/uiConstant";

export const LOAD_ORDERS = "LOAD_ORDERS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_LENGTH = "GET_ORDERS_LENGTH";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const SET_ORDER = "SET_ORDER";
export const EDIT_ORDERS = "EDIT_ORDERS";
export const LOADING = "LOADING";
export const GET_ORDER_FLIGHT_RECORD = "GET_ORDER_FLIGHT_RECORD";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const ERROR = "ERROR";

export const getEntityOrderCounts = (entityId) => {
  return async (dispatch) => {
    const response = await fetch(
      new URL(
        `/api/entity-admin/1/${entityId}/entitlement-counts`,
        process.env.REACT_APP_SOCKET_URL
      ).toString()
    );
    const ordersLengthResponse = await response.json();
    dispatch({
      type: GET_ORDERS_LENGTH,
      payload: ordersLengthResponse,
    });
  };
};

export const loadOrders = ({ limit, page, initial = true }) => {
  return (dispatch, getState) => {
    const entityId = getState().authenticate.entityId;
    if (initial && isEmpty(getState().orders.orders)) {
      const [request, abortController] = apiRequest({
        url: `/api/entity-admin/1/${entityId}/entitlements?limit=${limit}&page=${page}`,
        method: "GET",
        successDispatchType: [{ type: GET_ORDERS }, { type: LOADING }],
        preDispatchType: {
          type: START_ACTION,
          payload: { action: { name: GET_ORDERS } },
        },
        postDispatchType: {
          type: STOP_ACTION,
          payload: { name: GET_ORDERS },
        },
      });
      dispatch(request);
      return abortController;
    }
    if (!initial) {
      const [request] = apiRequest({
        url: `/api/entity-admin/1/${entityId}/entitlements?limit=${limit}&page=${page}`,
        method: "GET",
        successDispatchType: [{ type: GET_ORDERS }],
      });
      dispatch(request);
    }
  };
};

export const clearOrders = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDERS,
    });
  };
};

export const setOrders = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER,
      payload: order,
    });
  };
};

export const checkInOrder = ({ entitlementId, ...rest }) => {
  return async (dispatch) => {
    const [request, abortController] = apiRequest({
      url: `/api/entity-admin/1/checkin-entitlement/${entitlementId}`,
      method: "PUT",
      data: rest,
      successDispatchType: EDIT_ORDERS,
      successMessage: "CheckedIn",
    });
    dispatch(request);
    return abortController;
  };
};

export const getOrderFlightRecord = ({ entitlementId }) => {
  return async (dispatch) => {
    const response = await fetch(
      new URL(
        `/api/entity-admin/1/${entitlementId}/flight-record`,
        process.env.REACT_APP_SOCKET_URL
      ).toString()
    );
    const getOrderFlightRecordResponse = await response.json();
    dispatch({
      type: GET_ORDER_FLIGHT_RECORD,
      payload: getOrderFlightRecordResponse,
    });
  };
};

export const checkOutOrder = ({ entitlementId, ...rest }) => {
  return (dispatch) => {
    const [request, abortController] = apiRequest({
      url: `/api/entity-admin/1/checkout-entitlement/${entitlementId}`,
      method: "PUT",
      data: rest,
      successDispatchType: EDIT_ORDERS,
      successMessage: "CheckedOut",
    });
    dispatch(request);
    return abortController;
  };
};

export const cancelOrderFromProvider = ({ entitlementId, ...rest }) => {
  return (dispatch) => {
    const [request, abortController] = apiRequest({
      url: `/api/entity-admin/1/cancel-entitlement/${entitlementId}`,
      method: "PUT",
      data: rest,
      successDispatchType: EDIT_ORDERS,
      successMessage: "Canceled",
    });
    dispatch(request);
    return abortController;
  };
};

export const skipCheckin = ({ entitlementId, ...rest }) => {
  return async (dispatch) => {
    const [request, abortController] = apiRequest({
      url: `/api/entity-admin/1/skip-checkin/${entitlementId}`,
      method: "PUT",
      data: rest,
      successDispatchType: EDIT_ORDERS,
      successMessage: "Skipped",
    });
    dispatch(request);
    return abortController;
  };
};

export const enterToken = ({ entitlementId, ...rest }) => {
  return async (dispatch) => {
    const [request, abortController] = apiRequest({
      url: `/api/entity-admin/1/entitlement/${entitlementId}/enter-token`,
      method: "PUT",
      data: rest,
      successDispatchType: EDIT_ORDERS,
      successMessage: "Success",
    });
    dispatch(request);
    return abortController;
  };
};
