export const getDate = (date) => {};

export const dhm = (ms) => {
  let days = Math.floor(ms / (24 * 60 * 60 * 1000));
  let daysms = ms % (24 * 60 * 60 * 1000);
  let hours = Math.floor(daysms / (60 * 60 * 1000));
  let hoursms = ms % (60 * 60 * 1000);
  let minutes = Math.floor(hoursms / (60 * 1000));
  return `${days ? (days > 1 ? `${days} days` : `${days} day`) : ""} ${
    hours ? (hours > 1 ? `${hours} hours` : `${hours} hour`) : ""
  } ${
    minutes ? (minutes > 1 ? `${minutes} minutes` : `${minutes} minute`) : ""
  }`.trim();
};

export const localDateTimeString = (date) => {
  const newDate = new Date(date);
  return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`;
};

export const localDateString = (date) => {
  const newDate = new Date(date);
  return `${newDate.toLocaleDateString()}`;
};
