import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getIntersection } from "helpers/utils";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  const loggedIn = useSelector((state) => state.authenticate.login);
  const user = useSelector((state) => state.authenticate.user);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loggedIn) {
          return (
            <Redirect
              to={{ pathname: "/home", state: { from: props.location } }}
            />
          );
        }
        if (
          !getIntersection(
            roles,
            user.roles.map((role) => role.name)
          )
        ) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/home" }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
