import { LOAD_ENTITY_FLIGHT_RECORDS } from "store/constants/flightRecordConstant.js";

const initialState = {
  flightRecords: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ENTITY_FLIGHT_RECORDS:
      const result = Array.isArray(action.payload.flightRecords)
        ? action.payload.flightRecords.reduce((acc, flightRecord) => {
            acc[flightRecord._id] = flightRecord;
            return acc;
          }, {})
        : action.payload;
      return {
        ...state,
        flightRecords: result
      };
    default:
      return state;
  }
};
