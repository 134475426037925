import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import persistedReducer from "store/reducers/index";
import { persistStore } from "redux-persist";

const store = createStore(
  persistedReducer,
  process.env.NODE_ENV !== "production"
    ? composeWithDevTools(applyMiddleware(thunk))
    : compose(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export default { store, persistor };
