import {
  LOAD_PACKAGETYPES,
  LOAD_AIRPORTS,
  LOAD_INITIAL,
  REMOVE_ENTITY_AIRPORT,
  ADD_ENTITY_AIRPORT,
} from "store/constants/globalConstant";

const initialState = {
  packageTypes: {},
  airports: {},
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL:
      return {
        ...state,
        packageTypes: Array.isArray(action.payload.packageTypes)
          ? action.payload.packageTypes.reduce((acc, packageType) => {
              acc[packageType._id] = packageType;
              return acc;
            }, {})
          : action.payload,
        airports: Array.isArray(action.payload.airports)
          ? action.payload.airports.reduce((acc, airport) => {
              acc[airport._id] = airport;
              return acc;
            }, {})
          : action.payload,
        categories: action.payload.categories?.catgories ?? [],
      };
    case LOAD_PACKAGETYPES:
      return {
        ...state,
        packageTypes: Array.isArray(action.payload)
          ? action.payload.reduce((acc, packageType) => {
              acc[packageType._id] = packageType;
              return acc;
            }, {})
          : action.payload,
      };
    case LOAD_AIRPORTS:
      return {
        ...state,
        airports: Array.isArray(action.payload)
          ? action.payload.reduce((acc, airport) => {
              acc[airport._id] = airport;
              return acc;
            }, {})
          : action.payload,
      };
    case REMOVE_ENTITY_AIRPORT:
      const newAirports = { ...state.airports };
      delete newAirports[action.payload.airportId];
      return {
        ...state,
        airports: newAirports,
      };
    case ADD_ENTITY_AIRPORT:
      return {
        ...state,
        airports: {
          ...state.airports,
          [action.payload.airport._id]: action.payload.airport,
        },
      };
    default:
      return state;
  }
};
