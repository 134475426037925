import {
  LOAD_SERVICES,
  DELETE_SERVICE,
  CREATE_SERVICE,
  EDIT_SERVICE,
} from "../constants/serviceConstant";

const initialState = {
  services: [],
  licenceTypes: [],
  selectedService: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SERVICES:
      console.log(action.payload.packages);
      return {
        ...state,
        services: action.payload.packages,
        licenceTypes: action.payload.licenceTypes,
      };
    case DELETE_SERVICE:
      return {
        ...state,
        services: state.services.filter(
          (service) => service._id !== action.payload._id
        ),
      };
    case CREATE_SERVICE:
      return {
        ...state,
        services: [action.payload.package, ...state.services],
      };
    case EDIT_SERVICE:
      return {
        ...state,
        services: [
          action.payload.package,
          ...state.services.filter((x) => x._id !== action.payload.package._id),
        ],
      };

    default:
      return state;
  }
};
