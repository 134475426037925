import {
  // LOAD_ORDERS,
  GET_ORDERS,
  GET_ORDERS_LENGTH,
  CLEAR_ORDERS,
  SET_ORDER,
  EDIT_ORDERS,
  GET_ORDER_FLIGHT_RECORD,
  ERROR,
} from "../actions/OrdersAction";

const initialState = {
  error: {},
  order: {},
  orders: {},
  orderFlightRecord: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_LENGTH:
      return {
        ...state,
        ordersLength: action.payload,
      };
    case GET_ORDERS:
      const result = action.payload.orders.reduce((acc, entitlement) => {
        acc[entitlement._id] = entitlement;
        return acc;
      }, {});
      return {
        ...state,
        orders: { ...state.orders, ...result },
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case GET_ORDER_FLIGHT_RECORD:
      return {
        ...state,
        orderFlightRecord: action.payload,
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        orders: [],
      };
    case EDIT_ORDERS:
      state.orders[action.payload.newEntitlement._id] =
        action.payload.newEntitlement;
      const newOrders = { ...state.orders };
      return {
        ...state,
        orders: newOrders,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
