import {
  LOADING_EVENTS,
  UPDATE_EVENT,
  ADD_EVENT,
  REMOVE_EVENT,
  FILTER_EVENT,
} from "store/actions/CalendarAction";

const initialState = {
  events: [],
  filteredEvents: [],
};

const filterEvent = (identifier, category, original) => {
  return original.filter((e) => category.includes(e[identifier]));
};

export default (state = initialState, action) => {
  const { events } = state;
  switch (action.type) {
    case LOADING_EVENTS:
      return {
        ...state,
        events: action.payload.entitySlots,
        filteredEvents: action.payload.entitySlots,
      };
    case UPDATE_EVENT:
      const updateEvents = [
        {
          ...action.payload.slot,
          id: action.payload.slot._id,
          backgroundColor: action.payload.backgroundColor,
          borderColor: action.payload.borderColor,
          pilot: action.payload.slot.pilot.username,
          aircraft: action.payload.slot.aircraft.planeModel,
          resource: action.payload.slot.aircraft._id,
          staff: action.payload.slot.pilot._id,
          resourceIds: [
            action.payload.slot.pilot._id,
            action.payload.slot.aircraft._id,
          ],
        },
        ...events.filter((event) => event.id !== action.payload.slot._id),
      ];
      return {
        ...state,
        events: updateEvents,
        filteredEvents: updateEvents,
      };
    case ADD_EVENT:
      const newEvent = {
        ...action.payload.slot,
        id: action.payload.slot._id,
        backgroundColor: action.payload.backgroundColor,
        borderColor: action.payload.borderColor,
        aircraft: action.payload.slot.aircraft.planeModel,
        pilot: action.payload.slot.pilot.username,
        resource: action.payload.slot.aircraft._id,
        staff: action.payload.slot.pilot._id,
        resourceIds: [
          action.payload.slot.pilot._id,
          action.payload.slot.aircraft._id,
        ],
      };
      return {
        ...state,
        events: events.concat(newEvent),
        filteredEvents: events.concat(newEvent),
      };
    case REMOVE_EVENT:
      const newEvents = state.events.filter(
        (event) => event.id !== action.payload.id
      );
      return {
        ...state,
        events: newEvents,
        filteredEvents: newEvents,
      };
    case FILTER_EVENT:
      console.log(action);
      let updated = [...state.events];
      updated = action.aircrafts.length
        ? filterEvent("resource", action.aircrafts, updated)
        : updated;

      updated = action.pilots.length
        ? filterEvent("staff", action.pilots, updated)
        : updated;

      updated = action.services.length
        ? filterEvent("packageType", action.services, updated)
        : updated;

      return {
        ...state,
        filteredEvents: updated,
      };
    default:
      return state;
  }
};
