import {
  CLEAR_SUGGESTIONS,
  LOAD_SUGGESTIONS_BEGIN,
  UPDATE_AIRPORT_SUGGESTIONS,
  UPDATE_SUGGESTIONS,
} from "../constants/suggestionConstant";

const initialState = {
  suggestions: [],
  isLoading: {},
  cache: {},
};

const SuggestionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
      };

    case LOAD_SUGGESTIONS_BEGIN:
      return {
        ...state,
        isLoading: { [action.payload]: true },
      };

    case UPDATE_SUGGESTIONS: {
      return {
        ...state,
        suggestions: state.cache[action.payload.value] || action.payload.data,
        cache: state.cache[action.payload.value]
          ? {
              ...state.cache,
              [action.payload.value]: state.cache[action.payload.value],
            }
          : {
              ...state.cache,
              [action.payload.value]: action.payload.data,
            },
        isLoading: { [action.payload.id]: false },
      };
    }
    case UPDATE_AIRPORT_SUGGESTIONS:
      return {
        ...state,
        suggestions:
          state.cache[action.payload.value] || action.payload.airports,
        cache: state.cache[action.payload.value]
          ? {
              ...state.cache,
              [action.payload.value]: state.cache[action.payload.value],
            }
          : {
              ...state.cache,
              [action.payload.value]: action.payload.airports,
            },
        isLoading: { [action.payload.id]: false },
      };

    default:
      return state;
  }
};

export default SuggestionReducer;
