import {
  RESET_FORM,
  SUBMIT_FORM,
  SUBMIT_FORM_RESET,
  SELECT,
  EDIT,
} from "../constants/formConstant";

const initialState = {
  selected: {},
  isEditing: false,
  submitted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT:
      return {
        ...state,
        selected: action.payload,
      };
    case EDIT:
      return {
        ...state,
        isEditing: true,
      };
    case RESET_FORM:
      return {
        ...state,
        selected: {},
        isEditing: false,
      };
    case SUBMIT_FORM:
      return {
        ...state,
        submitted: true,
      };
    case SUBMIT_FORM_RESET:
      return {
        ...state,
        submitted: false,
      };
    default:
      return state;
  }
};
