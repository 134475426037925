export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

function enqueueSnackbar(notification) {
  return (dispatch) => {
    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        key: new Date().getTime() + Math.random(),
        ...notification,
        options: {
          ...notification.options,
          autoHideDuration: 1500,
          preventDuplicate: true,
        },
      },
    });
  };
}

function removeSnackbar(key) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_SNACKBAR,
      key,
    });
  };
}

export { enqueueSnackbar, removeSnackbar };
