import {
  SET_CURRENT_USER,
  REMOVE_CURRENT_USER,
  EDIT_CURRENT_USER,
} from "store/constants/authenticateConstant";
import { getEntityIdFromSession } from "helpers/utils";
import { ENTITY_ADMIN, ENTITY_STAFF, SYSTEM_ADMIN } from "constants/Roles";

const initialState = {
  login: false,
  entityId: getEntityIdFromSession(),
  user: {},
  isSystemAdmin: false,
  loggedInAt: Date.now(),
};

const authReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_CURRENT_USER:
      console.log(payload);
      const { account = {} } = payload;
      const entityAdminRole =
        account && account.roles
          ? account.roles.find(
              (role) => role.name === ENTITY_ADMIN || role.name === ENTITY_STAFF
            )
          : null;
      const systemAdmin =
        account && account.roles
          ? account.roles.find((role) => role.name === SYSTEM_ADMIN)
          : null;
      return {
        ...state,
        login: account._id ? true : false,
        user: account,
        entityId: entityAdminRole
          ? entityAdminRole.reference._id
          : getEntityIdFromSession() || null,
        isSystemAdmin: systemAdmin ? true : false,

        loggedInAt: Date.now(),
      };
    case REMOVE_CURRENT_USER:
      return initialState;
    case EDIT_CURRENT_USER:
      const { user = {} } = payload;
      return { ...state, user: { ...state.user, ...user } };
    default:
      return state;
  }
};

export default authReducer;
