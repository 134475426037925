import {
  LOAD_QUOTES,
  CREATE_UPDATE_QUOTE,
  DELETE_QUOTE,
} from "store/constants/quoteConstant";

const initialState = {
  quotes: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_QUOTES:
      return {
        ...state,
        quotes: action.payload.quotes.reduce((acc, quote) => {
          acc[quote._id] = quote;
          return acc;
        }, {}),
      };
    case CREATE_UPDATE_QUOTE:
      const {
        [action.payload.quote._id]: updatedQuote,
        ...rest
      } = state.quotes;
      return {
        ...state,
        quotes: {
          [action.payload.quote._id]: action.payload.quote,
          ...rest,
        },
      };
    case DELETE_QUOTE:
      const { [action.payload._id]: deletedQuote, ...remain } = state.quotes;
      return {
        ...state,
        quotes: {
          ...remain,
        },
      };

    default:
      return state;
  }
};
