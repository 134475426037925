import { LOAD_TODAY_SLOTS } from "../constants/slotConstant";

const initialState = {
  currentDateSlots: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TODAY_SLOTS:
      return {
        ...state,
        currentDateSlots: action.payload.slots,
      };

    default:
      return state;
  }
};
