import {
  displayName,
  displayAddress as formatAddress,
  isEmpty,
  minsToHrMins,
  routeReplace,
  toTitleCase,
} from "helpers/utils";
import _ from "lodash";
import moment from "moment-timezone";

import { entityRating } from "constants/Entity";
import { routeObject } from "routes";

import { localDateTimeString } from "helpers/dateTime";

function format({ data, formatObj, formatArray, ...rest }) {
  if (_.isPlainObject(data)) {
    return formatObj(data, { ...rest });
  }
  if (_.isArray(data)) {
    return formatArray(data, { ...rest });
  }
  return data;
}

export const formatOrderData = (dataArray) => {
  return Array.isArray(dataArray)
    ? dataArray.map((order) => {
        const slot = order.slots ? order.slots[0] : null;
        const product = order.package ? order.package : null;
        const { user = null } = order;
        const customer = user
          ? `${order.user.firstName} ${order.user.lastName}`.trim()
            ? `${order.user.firstName} ${order.user.lastName}`
            : order.user.username
          : "";
        return {
          orderId: order._id,
          packageType: product ? product.packageType.name : "No Package",
          customer,
          resource: slot
            ? slot.resource
              ? slot.resource.planeModel
              : "Not Assigned"
            : "No Slot",
          createdAt: moment(order.createdAt).format("YYYY-MM-DD hh:mm A"),
          status: toTitleCase(order.status),
          staff: slot
            ? slot.staff
              ? `${slot.staff.firstName} ${slot.staff.lastName}`.trim()
                ? `${slot.staff.firstName} ${slot.staff.lastName}`
                : slot.staff.username
              : "Not Assigned"
            : "No Slot",
          productName: product ? product.name : null,
          flightDate: slot
            ? moment(slot.startTime).format("YYYY-MM-DD hh:mm A")
            : "No Slot",
          phoneNumber: user ? user.primaryPhone : null,
          email: user ? user.email : null,
          packagePrice: product ? product.price : null,
          passenger: order.passenger,
          totalAmount: order.totalAmount ? order.totalAmount : null,
          totalWeight: order.weights.join(", "),
          productId: product ? product._id : null,
        };
      })
    : [];
};

export const formatStudentData = (data) => {
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      const { email, primaryPhone, _id, ...rest } = data;
      return {
        _id,
        email,
        name: displayName(rest),
        phone: primaryPhone || "N/A",
      };
    });
  const formatObj = (dataObj) => {
    const { email, primaryPhone, _id, ...rest } = dataObj;
    return {
      _id,
      email,
      name: displayName(rest),
      phone: primaryPhone || "N/A",
    };
  };

  return format({ data, formatArray, formatObj });
};

export const formatFlightRecordData = (dataArray) => {
  return Array.isArray(dataArray)
    ? dataArray.map((data) => {
        const {
          resource,
          _id,
          user,
          staff,
          createdAt,
          status,
          hobbsStartTime,
          hobbsEndTime,
          tachStartTime,
          tachEndTime,
          instructionalTime,
          numberOfLanding,
          comment,
        } = data;
        const { registration, planeModel } = resource ? resource : {};
        const { email } = user ? user : {};

        const dataFormat = {
          _id,
          aircraft: registration || "N/A",
          status,
          userEmail: email,
          instructor: displayName(staff || {}),
          date: moment(createdAt).format("YYYY-MM-DD hh:mm A"),
          model: planeModel || "N/A",
          hobbsTotal: hobbsEndTime - hobbsStartTime,
          tachTotal: tachEndTime - tachStartTime,
          hobbsStartTime,
          hobbsEndTime,
          tachStartTime,
          tachEndTime,
          instructionalTime,
          numberOfLanding,
          comment,
        };
        return isEmpty(dataFormat) ? data : dataFormat;
      })
    : [];
};

export const formatPilotData = (data) => {
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      const {
        email,
        primaryPhone,
        _id,
        documents,
        roles,
        firstName,
        lastName,
        ...rest
      } = data;
      return {
        ...rest,
        _id,
        email,
        firstName,
        lastName,
        name: displayName(rest),
        phone: primaryPhone || "N/A",
        documents,
        roles,
        isDocumentPublic: roles.find((role) => role.name === "entity staff")
          .public,
      };
    });
  const formatObj = (dataObj) => {
    const {
      email,
      primaryPhone,
      _id,
      documents,
      roles,
      firstName,
      lastName,
      ...rest
    } = dataObj;
    return {
      ...rest,
      _id,
      email,
      firstName,
      lastName,
      name: displayName(rest),
      phone: primaryPhone || "N/A",
      documents,
      roles,
      isDocumentPublic: roles.find((role) => role.name === "entity staff")
        .public,
    };
  };

  return format({ data, formatArray, formatObj });
};

export const formatServiceData = (dataArray) => {
  return Array.isArray(dataArray)
    ? dataArray.map((data) => {
        const { resources, airport, packageType, ...rest } = data;
        return {
          ...rest,
          serviceType: packageType.name || "",
          airport: airport._id || airport,
          resources: resources.map((resource) => {
            return resource._id ? resource._id : resource;
          }),
          packageType: packageType._id || packageType,
        };
      })
    : {
        ...dataArray,
        serviceType: dataArray.packageType.name || "",
        airport: dataArray.airport._id || dataArray.airport,
        resources: dataArray.resources.map((resource) => {
          return resource._id ? resource._id : resource;
        }),
        packageType: dataArray.packageType._id || dataArray.packageType,
      };
};

export const formatSlotData = (data) => {
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      const {
        resource,
        staff,
        packageType,
        startTime,
        endTime,
        extUser,
        ...rest
      } = data;
      console.log(data);
      return {
        startTime: moment(startTime).format("LT"),
        startDate: moment(startTime).format("MM DD YYYY"),
        endDate: moment(endTime).format("MM DD YYYY"),
        endTime: moment(endTime).format("LT"),
        student: displayName(extUser),
        planeModel: resource ? resource.planeModel : "N/A",
        registration: resource ? resource.registration : "N/A",
        staff: displayName(staff),
        ...rest,
      };
    });
  const formatObj = (dataObj) => {
    const {
      resource,
      staff,
      packageType,
      startTime,
      endTime,
      extUser,
      ...rest
    } = dataObj;

    return {
      startTime: moment(startTime).format("LT"),
      startDate: moment(startTime).format("MM DD YYYY"),
      endDate: moment(endTime).format("MM DD YYYY"),
      endTime: moment(endTime).format("LT"),
      student: displayName(extUser),
      planeModel: resource ? resource.planeModel : "N/A",
      registration: resource ? resource.registration : "N/A",
      staff: displayName(staff),
      ...rest,
    };
  };

  return format({ data, formatArray, formatObj });
};

export const formatCalendarSlotData = (data) => {
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      const {
        _id,
        resource,
        staff,
        packageType,
        startTime,
        endTime,
        extUser,
        package: service,
        user,
        // ...rest
      } = data;

      return {
        id: _id,
        title: packageType
          ? packageType.name
          : service
          ? service.packageType.name
          : "Undefined Title",
        firstName: extUser ? extUser.firstName : user ? user.firstName : "",
        lastName: extUser ? extUser.lastName : user ? user.lastName : "",
        email: extUser ? extUser.email : user ? user.email : "",
        start: startTime,
        end: endTime,
        packageType: packageType
          ? packageType._id
          : service
          ? service.packageType._id
          : "",
        aircraft: resource ? resource.registration : "",
        pilot: staff ? staff.username : "",
        staff: staff ? staff._id : "",
        resource: resource ? resource._id : "",

        resourceIds: [
          resource ? resource._id : "",
          staff ? staff._id : "",
        ].filter((value) => value !== ""),
      };
    });
  const formatObj = (dataObj) => {
    const {
      _id,
      resource,
      staff,
      packageType,
      startTime,
      endTime,
      extUser,
      // ...rest
    } = dataObj;

    return {
      _id,
      start: startTime,
      end: endTime,
      firstName: extUser.firstName,
      lastName: extUser.lastName,
      email: extUser.email,
      title: packageType.name,
      packageType: packageType._id,
      aircraft: resource,
      pilot: staff,
    };
  };
  return format({ data, formatArray, formatObj });
};

export const formatRequestData = (data) => {
  const formatObj = (dataObj) => {
    const {
      user = {},
      preferenceAircrafts = [],
      legs = [],
      type,
      charter,
      entity = [],
      routeType,
      createdAt,
      timeZone,
      ...rest
    } = dataObj;
    const createdDate = new Date(createdAt);

    const formattedLegs = legs.map((leg = {}) => {
      const { departureDate = "", origin = {}, destination = {} } = leg;
      const timeZoneDate = new Intl.DateTimeFormat("en-US", {
        dateStyle: "short",
        timeStyle: "long",
        timeZone,
      }).format(new Date(departureDate));
      const [dateString = "N/A", timeString = "N/A"] =
        timeZoneDate?.split(",") || [];

      return {
        legId: leg._id,
        etd: timeZoneDate || "N/A",
        flightDate: dateString,
        flightTime: timeString,
        departure: `${origin?.icao}-${origin?.name}`,
        arrival: `${destination?.icao}-${destination?.name}`,
        origin,
        destination,
      };
    });
    const [leg = {}] = formattedLegs;
    //todo this need to be change
    const [{ category = "All", model = "All" } = {}] = preferenceAircrafts;
    const detailsPreferenceAircrafts = preferenceAircrafts.reduce(
      (prev, curr, index) => {
        // concat String and add comma
        const lastItem = index === preferenceAircrafts.length - 1;
        // add comma if not lastitem
        const category =
          // if undefined use empty string which will be trim at the end
          `${prev?.category || ""} ${
            curr?.category && !lastItem
              ? `${curr?.category},`
              : curr?.category && lastItem
              ? curr?.category
              : ""
          }`.trim() || "All";
        const model =
          `${prev?.model || ""} ${
            curr?.model && !lastItem
              ? `${curr?.model},`
              : curr?.model && lastItem
              ? curr?.model
              : ""
          }`.trim() || "All";
        return {
          ...prev,
          category,
          model,
        };
      },
      {}
    );
    const data = {
      ...leg,
      legs: formattedLegs,
      customerName: `${user?.firstName || ""} ${user?.lastName || ""}`.trim(),
      customerEmail: user?.email,
      customerPhone: user?.primaryPhone,
      routeType,
      category,
      model,
      entity,
      type,
      detailsPreferenceAircrafts,
      createdAt: `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`,
      ...rest,
    };
    if (charter) {
      data.charterName = charter?.name;
      data.charterPrice = charter?.originalPrice;
      data.charterModel = charter?.resources?.[0]?.planeModel;
      data.charterSeat = charter?.resources?.[0]?.passengerLimitation
        ? Number(charter.resources[0]?.passengerLimitation)
        : 1;
    }
    return data;
  };

  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};
const retrivFileNameAndLocation = (object) => {
  const {
    key = "",
    location = "",
    signedKey = "",
    signedLocation = "",
  } = object;
  const keyArray = signedKey
    ? signedKey.split("fileName=")
    : key.split("fileName=");
  return {
    name: keyArray[keyArray.length - 1],
    location: signedLocation || location,
  };
};
export const formatQuoteData = (data) => {
  const formatObj = (dataObj) => {
    const {
      price = {},
      quoteNumber = "",
      quoteDate,
      flights = [],
      client = {},
      quoteContract = {},
      flightItinerary = {},
      refundPolicy = [],
      transaction,
      ...rest
    } = dataObj || {};
    let formatTransaction = transaction;
    const { subtotal = 0, fees = [] } = price;
    const { clientEmail = 0, clientPhone = [] } = client;
    const totalFees = fees.reduce((prev, cur) => {
      return prev + cur.amount;
    }, 0);
    const newQuoteDate = new Date(quoteDate);
    const formattedFlights = flights.map(({ date, estimate, _id, ...rest }) => {
      const { etd, ete } = estimate || {};
      const etdDate = new Date(etd);
      const etaMoment = moment(etd).add(ete, "minute");

      const flightDate = new Date(date);
      const { hr, mins } = minsToHrMins(ete);
      return {
        legId: _id,
        flightDate: flightDate.toLocaleDateString(),
        flightTime: flightDate.toLocaleTimeString(),
        eta: etaMoment.format("M/DD/YYYY hh:mm:ss a"),
        etd: `${etdDate.toLocaleDateString()} ${etdDate.toLocaleTimeString()}`,
        ete: `${hr || 0} hrs ${mins || 0} mins`.trim(),
        ...rest,
      };
    });
    const contract = retrivFileNameAndLocation(quoteContract);
    const itinerary = retrivFileNameAndLocation(flightItinerary);
    if (transaction) {
      const { payment = {}, refunds = [], ...transactionRest } = transaction;
      const paymentDate = new Date(payment.date);
      formatTransaction = {
        payment: { ...payment, date: paymentDate.toLocaleDateString() },
        refunds: refunds.map((refund) => {
          const refundDate = new Date(refund.date);
          return { ...refund, date: refundDate.toLocaleDateString() };
        }),
        ...transactionRest,
      };
    }

    function sortedIndex(array, value) {
      var low = 0,
        high = array.length;

      while (low < high) {
        var mid = (low + high) >>> 1;
        if (array[mid] < value) low = mid + 1;
        else high = mid;
      }
      return low;
    }
    let refundRate =
      refundPolicy[
        sortedIndex(
          refundPolicy.map(({ date }) => new Date(date)),
          Date.now()
        ) - 1
      ]?.rate || 0;

    return {
      clientEmail,
      clientPhone,
      contractName: contract.name,
      contractLocation: contract.location,
      itineraryName: itinerary.name,
      itineraryLocation: itinerary.location,
      quoteDate: newQuoteDate.toLocaleDateString(),
      quoteNumber: quoteNumber.split(".")[0],
      totalPrice: subtotal + totalFees,
      price: subtotal,
      fees: fees.map((fee) => ({ ...fee, name: toTitleCase(fee.name) })),
      refundRate,
      flights: formattedFlights,
      transaction: formatTransaction,
      ...rest,
    };
  };

  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};

export const formatProfileData = (data) => {
  const formatObj = (dataObj) => {
    const { email, firstName, lastName, primaryPhone, ...rest } = dataObj || {};
    const displayName = `${firstName} ${lastName}`;
    return {
      displayName,
      email,
      firstName,
      lastName,
      primaryPhone,
      ...rest,
    };
  };

  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};

export const formatBusinessData = (data) => {
  const formatObj = (dataObj) => {
    const { address, rated, ...rest } = dataObj || {};
    const displayAddress = formatAddress(address);

    return {
      ...rated,
      argusLogo: entityRating.argus[rated?.argusRating],
      wyvernLogo: entityRating.wyvern[rated?.wyvernRating],
      displayAddress,
      address,
      ...rest,
    };
  };

  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};

export const formatNotificationData = (data) => {
  const formatObj = (dataObj) => {
    const { notification, createdAt, ...rest } = dataObj || {};
    const { _id, relatedModel, ...notificationRest } = notification || {};
    let url = "";
    if (relatedModel?.modelName === "Quotation") {
      url = routeReplace(routeObject.entity.QUOTE_ROUTE, [
        relatedModel?.resource?._id,
      ]);
    }
    if (relatedModel?.modelName === "Request") {
      url = routeReplace(routeObject.entity.REQUEST_ROUTE, [
        relatedModel?.resource?._id,
      ]);
    }

    return {
      ...notificationRest,
      redirect: url,
      createdDate: localDateTimeString(createdAt),
      ...rest,
    };
  };
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};

export const formatMessage = (data) => {
  const formatObj = (dataObj) => {
    const { type, message, createdAt, readByRecipients, ...rest } =
      dataObj ?? {};
    const read = readByRecipients?.length > 0;
    const dateObject = new Date(createdAt);
    const createdAtTime =
      dateObject.toString() !== "Invalid Date"
        ? dateObject.toLocaleTimeString()
        : "";
    return {
      read,
      messageType: type,
      message,
      messageCreatedAt: createdAtTime,
      ...rest,
    };
  };
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};

export const formatChatRoom = (data, currentUser) => {
  const formatObj = (dataObj) => {
    const { userIds, lastMessage, chatInitiator, ...rest } = dataObj || {};
    const { messageType, postedByUser, messageCreatedAt, message } =
      formatMessage(
        {
          ...lastMessage,
          postedByUser: userIds.find(
            (user) => user._id === lastMessage?.postedByUser
          ),
        } ?? {}
      );
    const initiator = userIds.find((user) => user._id === chatInitiator);
    const others = userIds.filter((user) => user._id !== currentUser._id);

    return {
      initiator,
      messagePostedByUser: postedByUser,
      messageType,
      message,
      messageCreatedAt,
      others,
      chatRoomName: others
        .map((user) => `${user.firstName} ${user.lastName}`)
        .join(" "),
      chatRoomImage: others
        .map((user) => user.images?.profileImage)
        .filter((image) => image),
      ...rest,
    };
  };
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};

export const formatCharterData = (data) => {
  const formatObj = (dataObj) => {
    const {
      airportFrom,
      airportTo,
      dateFrom,
      dateTo,
      resources,
      flightDuration,
      ...rest
    } = dataObj || {};
    const {
      registration,
      planeModel,
      passengerLimitation: seats,
      _id: resourceId,
    } = resources?.[0] ?? {};
    const localDateFrom = localDateTimeString(dateFrom);
    const localDateTo = localDateTimeString(dateTo);
    const { hr, mins } = minsToHrMins(flightDuration);
    const flights = [
      {
        legId: 1,
        departure: `${airportFrom.icao}-${airportFrom.name}`,
        arrival: `${airportTo.icao}-${airportTo.name}`,
        flightDate: `${localDateFrom}-${localDateTo}`,
        ete: `${hr} hr ${mins} min`,
      },
    ];
    return {
      flights,
      airportTo,
      airportFrom,
      registration,
      planeModel,
      resource: resourceId,
      seats,
      dateRange: `${localDateFrom}-${localDateTo}`,
      dateFrom,
      dateTo,
      localDateFrom,
      localDateTo,
      flightDuration,
      ...rest,
    };
  };
  const formatArray = (dataArray) =>
    dataArray.map((data) => {
      return formatObj(data);
    });

  return format({ data, formatArray, formatObj });
};
