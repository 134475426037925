// @ts-ignore

import { addMinutes, format } from "date-fns";
import { diff } from "deep-object-diff";
import _ from "lodash";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
const { zonedTimeToUtc, utcToZonedTime } = require("date-fns-tz");

export const getTotal = (subtotal: number, fees: []): number => {
  const totalPrice = fees.reduce((prev: number, cur: any) => {
    return prev + cur.amount;
  }, subtotal);
  return totalPrice;
};

export const getWholeQuoteNumber = (quoteNumber: string = ""): string => {
  const quoteNumberArray = quoteNumber.split(".");
  const first = quoteNumberArray.shift();
  const last = quoteNumberArray.pop();

  return `${(first ? Number(first) : 0) + (last ? Number(last) : 0)}`.substring(
    0,
    12
  );
};

export const strToBool = (value: string): boolean => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return !!value;
};

export const generateBsonId = (): string => {
  const ObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s: any) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  const id = ObjectId();
  return id;
};

export const generateUId = (): string => {
  return uuidv4();
};

export const getTimezone = (): string => {
  return moment.tz.guess();
};

export const localDateToUTC = (date: string | Date, zone: string) => {
  return zonedTimeToUtc(new Date(date), zone);
};

export const UtcToLocalDate = (date: string | Date, zone: string) => {
  return utcToZonedTime(new Date(date), zone);
};

export const addDuration = (date: string, duration: number): Date => {
  const result = addMinutes(new Date(date), duration);
  return result;
};

interface IfcObject {
  id: string;
  [key: string]: string;
}

export const dateRangeOverlaps = (
  a_start: Date,
  a_end: Date,
  b_start: Date,
  b_end: Date
): boolean => {
  if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
  if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
};

export const routeReplace = (
  route: string = "",
  Ids: string[] = []
): string => {
  const routeArray = route?.split("/");
  const routeIds = Ids;
  const modifiedRoute = routeArray.map((routeString) => {
    if (routeString.startsWith(":") && routeIds?.length > 0) {
      return routeIds?.shift();
    }
    return routeString;
  });
  return modifiedRoute.join("/");
};

export const mergeArrayObjectById = (
  a1: IfcObject[],
  a2: IfcObject[]
): IfcObject[] => {
  return _.map(a1, function (item) {
    return _.extend(item, _.find(a2, { id: item.id }));
  });
};

export const getDiffInObject = (
  originObject: {
    [key: string]: string;
  },
  updatedObject: any
) => diff(originObject, updatedObject);

export const formatCamelCase = (camelCaseStr: string): string => {
  return (
    camelCaseStr
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const getEntityIdFromSession = (): string | null => {
  return localStorage.getItem("entityId");
};

interface userParmas {
  firstName: string;
  lastName: string;
  email: string;
}

export const getInitial = ({ firstName, lastName }: userParmas): string => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};

export const pathify = (path: string): string => {
  return (
    path
      // eslint-disable-next-line no-useless-escape
      .replace(/[\.:"'\(\)\[\],\s\/\?\=@]/gi, "-")
      // eslint-disable-next-line no-useless-escape
      .replace(/\-+/gi, "-")
      // eslint-disable-next-line no-useless-escape
      .replace(/\-+$/gi, "")
      .toLowerCase()
  );
};

export const createFormData = (
  formData: any,
  key: string,
  data: [],
  exceptions: string[] = []
) => {
  if (!exceptions.includes(key)) {
    if (data === Object(data) || Array.isArray(data)) {
      for (const i in data) {
        createFormData(formData, key + "[" + i + "]", data[i]);
      }
    } else {
      formData.append(key, data);
    }
  }
};

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const cleanObj = <T extends object>(obj: T) =>
  (Object.keys(obj) as Array<keyof typeof obj>).reduce((acc, key) => {
    let value = obj[key];
    if (value === null || value === undefined) {
      return { ...acc, [key]: "" };
    }
    return { ...acc, [key]: value };
  }, {});

export const isObject = (data: any) => {
  return _.isPlainObject(data);
};

export const isEmpty = (data: object | []) => {
  return _.isEmpty(data);
};

export const cleanFormValue = (obj: any): object =>
  (Object.keys(obj) as Array<keyof typeof obj>).reduce((acc: any, key: any) => {
    let value = obj[key];
    if ((value && !isObject(value)) || value === 0) {
      return { ...acc, [key]: value };
    }
    if (isObject(value)) {
      const prev = { ...acc };
      if (!isEmpty(cleanFormValue(value))) {
        prev[key] = cleanFormValue(value);
      }
      return prev;
    }
    return { ...acc };
  }, {});

export const displayName = (
  userObj: userParmas = { firstName: "", lastName: "", email: "" }
): string => {
  return (
    `${userObj.firstName || ""} ${userObj.lastName || ""}`.trim() ||
    userObj.email ||
    "N/A"
  );
};

interface addressParmas {
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

export const displayAddress = (
  address: addressParmas = { street: "", city: "", zipCode: "", state: "" }
): string => {
  const { state, street, city, zipCode } = address;
  return (
    `${street ? street + "," : ""} ${city ? city + "," : ""} ${
      state ? state + "," : ""
    } ${zipCode ? zipCode + "," : ""}`.trim() || "N/A"
  );
};

export const displayTime = (date: Date) => {
  return format(date, "M/D/YYYY");
};

export const covertHrToTimeObject = (time: number | string | undefined) => {
  if (!time && time !== 0) {
    return moment();
  }
  const [hr, percentOfMinute] = String(time).split(".");
  const minute = percentOfMinute
    ? Math.round(Number(`.${percentOfMinute}`) * 60)
    : 0;
  return moment({ hour: Number(hr), minute });
};

export const hrsToHumanReadable = (time: number | string) => {
  const [hr, percentOfMinute] = String(time).split(".");
  const minutes = percentOfMinute
    ? Math.round(Number(`.${percentOfMinute}`) * 60)
    : 0;
  return { hr, minutes };
};

export const minsToHrMins = (minutes: number | undefined) => {
  if (!minutes) {
    return {
      hr: 0,
      mins: 0,
    };
  }
  const hr = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  return {
    hr,
    mins,
  };
};

// try to replace moment when time allow
export const covertHrToTimeObject2 = (time: number | string) => {
  const [hr, percentOfMinute] = String(time).split(".");
  const minute = percentOfMinute
    ? Math.round(Number(`.${percentOfMinute}`) * 60)
    : 0;
  return format(
    new Date().setHours(Number(hr), minute),
    "YYYY-MM-DD[T]HH:mm:ssZZ"
  );
};

export const covertDateToDateTimeObject = (
  date: Date | string,
  format?: string
) => {
  return moment(date, format);
};

export const removeFromObject = (obj: any, arrayOfKey?: any) => {
  return _.omit(obj, arrayOfKey);
};

export const throttle = (func: any, timer: number) => {
  return _.throttle(func, timer);
};

interface businessHoursProps {
  daysOfWeek: Number[];
  startTime: string;
  endTime: string;
}
interface checkTimeSlotAvailableProps {
  start: Date;
  end: Date;
  businessHours: businessHoursProps[];
}

export const checkTimeSlotAvailable = ({
  businessHours,
  start,
  end,
}: checkTimeSlotAvailableProps) => {
  let isNotAvaliable = false;
  if (businessHours.length > 0) {
    isNotAvaliable = true;
    const extra = `${covertDateToDateTimeObject(start).format("YYYY-MM-DD")}`;
    const startTime = covertDateToDateTimeObject(start, "HH:mm");
    const endTime = covertDateToDateTimeObject(end, "HH:mm");
    const todayHours = businessHours.find((day) => {
      return day.daysOfWeek.includes(startTime.day());
    });
    console.log(businessHours, todayHours, startTime.day());
    if (todayHours) {
      const todayStartTime = covertDateToDateTimeObject(
        `${extra} ${todayHours.startTime}`
      );
      const todayEndTime = covertDateToDateTimeObject(
        `${extra} ${todayHours.endTime}`
      );

      if (
        startTime.isBetween(todayStartTime, todayEndTime, undefined, "[]") &&
        endTime.isBetween(todayStartTime, todayEndTime, undefined, "[]")
      ) {
        isNotAvaliable = false;
      }
    }
  }
  return isNotAvaliable;
};

export const genRandomString = (
  length: number,
  type: string = "number"
): string => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const num = "0123456789";
  const stringType = type === "number" ? num : chars;
  let result = "";
  for (let i: number = length; i > 0; i -= 1)
    result += stringType[Math.floor(Math.random() * stringType.length)];
  return result;
};

export const getIntersection = (array1: [], array2: []): boolean => {
  return _.intersection(array1, array2).length > 0;
};

interface timeParmas {
  hour: number;
  minute: number;
}

export const timeConvert = (
  duration: number,
  option: { durationInHour?: boolean } = {}
): timeParmas => {
  let durationMinute = duration;
  if (option.durationInHour) {
    durationMinute = duration * 60;
  }

  const hours = durationMinute / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  return { hour: rhours, minute: rminutes };
};

export const generateAllYearsTillNow = (numberOfYears: number): number[] => {
  const years = [];
  const thisYear = new Date().getFullYear();
  let yearNeeded = thisYear - numberOfYears;
  for (let i = thisYear; i >= yearNeeded; i -= 1) {
    years.push(i);
  }
  return years;
};

export const convertHoursToMinutes = (hr: number): number => {
  const hrNumber = Number(hr) || 0;
  const minutes = hrNumber * 60;
  return Math.ceil(minutes);
};
