import { apiRequest } from "helpers/api/apiRequest";
import { formatCalendarSlotData } from "helpers/formatData";
import { STOP_ACTION, START_ACTION } from "store/constants/uiConstant";
import { isEmpty } from "helpers/utils";

export const LOADING_EVENTS = "LOADING_EVENTS";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const ADD_EVENT = "ADD_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const FILTER_EVENT = "FILTER_EVENT";

const Colors = {
  1: "#DB0038",
  2: "#F7C200",
  3: "#519DD9",
  4: "#53BAC1",
  5: "#EDEDED",
};

export const loadEvents = () => async (dispatch, getState) => {
  const entityId = getState().authenticate.entityId;
  if (isEmpty(getState().calendar.filteredEvents)) {
    const [request, abortController] = apiRequest({
      url: `/api/entity-admin/1/entity/${entityId}/slots`,
      method: "GET",
      successDispatchType: LOADING_EVENTS,
      formatData: {
        dataName: "entitySlots",
        formatFunc: formatCalendarSlotData,
      },
      preDispatchType: {
        type: START_ACTION,
        payload: { action: { name: LOADING_EVENTS } },
      },
      postDispatchType: {
        type: STOP_ACTION,
        payload: { name: LOADING_EVENTS },
      },
    });

    dispatch(request);

    return abortController;
  }
};

export const updateEvent = (event) => {
  console.log();
  return (dispatch, getState) => {
    const { _id, id, pilot, packageType, start, end, ...rest } = event;
    const packageTypes = getState().global.packageTypes;
    const thePackageType = packageTypes[packageType];
    let color;
    if (thePackageType) {
      if (thePackageType.color) {
        color = thePackageType.color.color;
      } else {
        color = Colors[thePackageType.colorCode];
      }
    } else {
      color = "grey";
    }
    const [request] = apiRequest({
      url: `/api/entity-admin/1/schedule/slot/${id}`,
      method: "PUT",
      data: {
        packageType,
        staff: pilot,
        startTime: start,
        endTime: end,
        ...rest,
      },
      dispatchData: { backgroundColor: color, borderColor: color },
      successDispatchType: UPDATE_EVENT,
      successMessage: "Update event successful",
      formatData: { dataName: "slot", formatFunc: formatCalendarSlotData },
    });
    dispatch(request);
  };
};

export const addEvent = (event) => {
  return async (dispatch, getState) => {
    const {
      firstName,
      lastName,
      email,
      startTime,
      endTime,
      pilot,
      resource,
      packageType,
    } = event;
    const entityId = getState().authenticate.entityId;
    const packageTypes = getState().global.packageTypes;
    const thePackageType = packageTypes[packageType];
    let color;
    if (thePackageType) {
      if (thePackageType.color) {
        color = thePackageType.color.color;
      } else {
        color = Colors[thePackageType.colorCode];
      }
    } else {
      color = "grey";
    }
    const [request] = apiRequest({
      url: `/api/entity-admin/1/schedule/slot`,
      method: "POST",
      data: {
        entityId,
        firstName,
        lastName,
        email,
        startTime,
        endTime,
        packageType,
        resource,
        staff: pilot,
      },
      dispatchData: { backgroundColor: color, borderColor: color },
      successDispatchType: ADD_EVENT,
      successMessage: "Event added",
      formatData: { dataName: "slot", formatFunc: formatCalendarSlotData },
    });
    dispatch(request);
  };
};

export const removeEvent = (event) => {
  return (dispatch) => {
    const [request] = apiRequest({
      url: `/api/entity-admin/1/schedule/slot/${event.id}`,
      method: "DELETE",
      successDispatchType: REMOVE_EVENT,
      successMessage: "Remove event successful",
    });
    dispatch(request);
  };
};

export const filterEvent = (aircrafts, pilots, services, reservations) => {
  return { type: FILTER_EVENT, aircrafts, pilots, services, reservations };
};
