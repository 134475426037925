import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import persistStore from "store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";

import "./App.scss";
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import Notifier from "./views/Alerts/Notifier";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Authenticated from "components/Auth/Authenticated";
import ProtectedRoute from "components/Auth/ProtectedRoute";
import { ENTITY_ADMIN, ENTITY_STAFF, SYSTEM_ADMIN } from "constants/Roles";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import Loading from "components/Loading";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <Loading />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const Home = React.lazy(() => import("./views/Home/Home"));
const SignUpForm = React.lazy(() => import("views/OnBoarding/SignUp"));
const LoginForm = React.lazy(() => import("views/OnBoarding/Login"));

const { store, persistor } = persistStore;
const queryClient = new QueryClient();

class App extends Component {
  render() {
    return (
      <>
        {process.env.REACT_APP_ENV !== "production" && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Notifier />
                <QueryClientProvider client={queryClient}>
                  <HashRouter>
                    <React.Suspense fallback={loading()}>
                      <Switch>
                        <Route exact path="/">
                          <Redirect to="/home" />
                        </Route>
                        <Route
                          exact
                          path="/register"
                          name="Register Page"
                          render={(props) => <Register {...props} />}
                        />
                        <Route
                          exact
                          path="/404"
                          name="Page 404"
                          render={(props) => <Page404 {...props} />}
                        />
                        <Route
                          exact
                          path="/500"
                          name="Page 500"
                          render={(props) => <Page500 {...props} />}
                        />
                        <Authenticated
                          component={SignUpForm}
                          path="/operator-register"
                        />
                        <Authenticated component={LoginForm} path="/login" />
                        <Authenticated component={Home} path="/home" />

                        <ProtectedRoute
                          component={DefaultLayout}
                          roles={[ENTITY_ADMIN, ENTITY_STAFF]}
                          path="/entity"
                        />
                        <ProtectedRoute
                          component={DefaultLayout}
                          roles={[SYSTEM_ADMIN]}
                          path="/system"
                        />
                      </Switch>
                    </React.Suspense>
                  </HashRouter>
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                  />
                </QueryClientProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>
      </>
    );
  }
}

export default App;
