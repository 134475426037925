import { LOAD_INITIAL } from "../constants/globalConstant";
import {
  UPDATE_CONTACT_INFO,
  UPDATE_WEATHER_ALERT,
  UPDATE_HOURS_OF_OPERATION,
  GET_ENTITY_MANAGERS,
  REMOVE_ENTITY_MANAGERS,
  ADD_ENTITY_MANAGERS,
  ENTER_BUSINESS_DETAILS,
  CHANGE_ENTITY_IMAGE,
  GET_ENTITY_TASKS,
} from "../constants/entityConstant";

const initialState = {
  entity: {},
  entityRoleUsers: [],
  entityManagers: [],
  airports: [],
  tasks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL:
      return {
        ...state,
        entity: action.payload.entity
          ? action.payload.entity
          : state.entity || {},
        entityRoleUsers: action.payload.entityRoleUsers || [],
        airports: action.payload.airports || [],
      };
    case UPDATE_CONTACT_INFO:
      return {
        ...state,
        entity: action.payload.entity
          ? action.payload.entity
          : state.entity || {},
      };
    case UPDATE_WEATHER_ALERT:
      return {
        ...state,
        entity: action.payload.entity
          ? action.payload.entity
          : state.entity || {},
      };
    case UPDATE_HOURS_OF_OPERATION:
      return {
        ...state,
        entity: action.payload.entity
          ? action.payload.entity
          : state.entity || {},
      };
    case GET_ENTITY_MANAGERS:
      return {
        ...state,
        entityManagers: action.payload.managers,
      };
    case REMOVE_ENTITY_MANAGERS:
      return {
        ...state,
        entityManagers: state.entityManagers.filter((manager) => {
          return manager._id !== action.payload.user._id;
        }),
      };
    case ADD_ENTITY_MANAGERS:
      return {
        ...state,
        entityManagers: [...state.entityManagers, action.payload.user],
      };
    case ENTER_BUSINESS_DETAILS:
      return {
        ...state,
        entity: action.payload.entity
          ? action.payload.entity
          : state.entity || {},
      };
    case CHANGE_ENTITY_IMAGE:
      return {
        ...state,
        entity: action.payload.entity
          ? action.payload.entity
          : state.entity || {},
      };
    case GET_ENTITY_TASKS:
      return {
        ...state,
        tasks: action.payload.tasks ? action.payload.tasks : state.tasks || {},
      };
    default:
      return state;
  }
};
