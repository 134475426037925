import {
  LOAD_STUDENTS,
  ADD_STUDENTS,
  REMOVE_STUDENTS,
  LOAD_PILOTS,
  ADD_PILOT,
  REMOVE_PILOTS,
} from "../constants/userConstant";
import { removeFromObject } from "helpers/utils";

const initialState = {
  students: {},
  pilots: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_STUDENTS:
      const result = Array.isArray(action.payload.users)
        ? action.payload.users.reduce((acc, user) => {
            acc[user._id] = user;
            return acc;
          }, {})
        : action.payload;
      return {
        ...state,
        students: result,
      };
    case LOAD_PILOTS:
      const pilotResult = Array.isArray(action.payload.users)
        ? action.payload.users.reduce((acc, user) => {
            acc[user._id] = user;
            return acc;
          }, {})
        : action.payload;
      return {
        ...state,
        pilots: pilotResult,
      };
    case ADD_STUDENTS:
      return {
        ...state,
        students: {
          [action.payload.user._id]: action.payload.user,
          ...state.students,
        },
      };
    case REMOVE_STUDENTS:
      return {
        ...state,
        students: removeFromObject(state.students, action.payload.ids),
      };
    case ADD_PILOT:
      return {
        ...state,
        pilots: {
          [action.payload.user._id]: action.payload.user,
          ...state.pilots,
        },
      };
    case REMOVE_PILOTS:
      return {
        ...state,
        pilots: removeFromObject(state.pilots, action.payload.ids),
      };
    default:
      return state;
  }
};
