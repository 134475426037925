import { isEmpty, removeFromObject } from "helpers/utils";
import { History } from "history";
import { enqueueSnackbar } from "store/actions/AlertActions";
interface formatDataParams {
  dataName?: string;
  formatFunc: Function;
}

interface redirectParams {
  history: History;
  route: string;
  state: any;
}

interface apiRequestParams {
  url: string;
  method: string;
  data?: object;
  options?: object;
  successDispatchType?: string;
  successMessage?: string;
  dispatchData?: object;
  redirect?: redirectParams;
  formatData?: formatDataParams;
  preDispatchType?: object;
  postDispatchType?: object;
}

export const apiRequest = ({
  // Api Request Url
  url,
  // Api Request method
  method,
  // Api Request data
  data,
  // Api Request options
  options,
  // Redux Dispatch
  successDispatchType,
  // Redux Dispatch Message
  successMessage = "",
  // Data Dispatch with request payload
  dispatchData = {},
  // Redirect
  redirect,
  // Format Dispatch Data
  formatData = { formatFunc: (data: object) => data },
  // Dispatch before request
  preDispatchType,
  // Dispatch after everything
  postDispatchType,
}: apiRequestParams) => {
  //todo need to fix this
  // get format function and dataName
  const { dataName, formatFunc } = formatData;

  return (() => {
    const controller = new AbortController();
    const signal = controller.signal;
    return [
      async (dispatch: Function): Promise<any> => {
        if (preDispatchType) {
          dispatch(preDispatchType);
        }

        try {
          let formattedData = {};
          const newUrl = new URL(url, process.env.REACT_APP_SOCKET_URL);
          // make api request
          // todo multiple request promise all
          const response = await fetch(newUrl.toString(), {
            signal,
            method: method.toUpperCase(), // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include", // include, *same-origin, omit
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: data ? JSON.stringify(data) : undefined, // body data type must match "Content-Type" header
            ...options,
          });
          const jsonResponse = await response.json();

          // extract server message from api payload
          const { message } = jsonResponse;

          console.log("jsonResponse", jsonResponse);
          console.log("formattedData", formattedData || "No format");

          // api request Success
          if (response.ok) {
            // if formatData options exists format data using format function
            if (!isEmpty(formatData)) {
              formattedData = dataName
                ? formatFunc(jsonResponse[dataName])
                : formatFunc(jsonResponse);
            }

            // Dispatch Actions  Dispatch type present
            if (successDispatchType) {
              // check if multiple dispatch
              if (Array.isArray(successDispatchType)) {
                successDispatchType.forEach((dispatchType) => {
                  const newPayload = {
                    ...jsonResponse,
                    ...dispatchType.payload,
                    ...dispatchData,
                  };
                  if (dataName) {
                    newPayload[dataName] = formattedData;
                  }
                  dispatch({
                    type: dispatchType.type,
                    // payload remove any falsy value
                    payload: removeFromObject(newPayload),
                  });
                });
              } else {
                // single dispatch
                console.log("dispatch ", successDispatchType);
                const newPayload = {
                  ...jsonResponse,
                  ...dispatchData,
                };
                if (dataName) {
                  newPayload[dataName] = formattedData;
                }
                dispatch({
                  type: successDispatchType,
                  payload: removeFromObject(newPayload),
                });
              }
            }

            // if server message or sucessMessage present dispatch notification
            if (message || successMessage) {
              dispatch(
                enqueueSnackbar({
                  message: message || successMessage,
                  options: { variant: "success" },
                })
              );
            }

            // if redirect option presnet redirect to route
            if (redirect) {
              redirect.history?.push({
                pathname: redirect.route,
                state: redirect.state,
              });
            }
            if (postDispatchType) {
              console.log("dispatch postDispatchType");
              dispatch(postDispatchType);
            }
          } else {
            // api request fail throw errors
            throw jsonResponse;
          }
        } catch (error) {
          //catch error thrown dispatch to notification
          if (signal && !signal.aborted) {
            dispatch(
              enqueueSnackbar({
                message: error?.message ?? "Error",
                options: { variant: "error" },
              })
            );
          }
          console.log(error);
          if (postDispatchType) {
            console.log("dispatch postDispatchType");
            dispatch(postDispatchType);
          }
        }
      },
      controller,
    ];
  })();
};
