import React from "react";
import CircularProgress from "views/Progress/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  center: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  relativeCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));

const Loading = ({ loadingPosition }) => {
  const classes = useStyles();
  return (
    <div
      className={
        classes[loadingPosition === "fixed" ? "center" : "relativeCenter"]
      }
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
