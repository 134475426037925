import React from "react";

// Dashboard
const Dashboard = React.lazy(() => import("views/Dashboard"));

//Users
const Students = React.lazy(() => import("views/Students/Students.jsx"));
const Pilot = React.lazy(() => import("views/Pilot/Pilot"));

// Calender
const Schedule = React.lazy(() => import("views/Calendar/CalendarContainer"));

// Order
const Orders = React.lazy(() => import("views/Orders/Orders"));

// Service
const Services = React.lazy(() => import("views/Services/Service"));
const NewService = React.lazy(() => import("views/Services/NewService"));

// Charters
const Charters = React.lazy(() => import("views/Charters/Charters"));
const NewCharter = React.lazy(() => import("views/Charters/NewCharter"));
const Charter = React.lazy(() => import("views/Charters/Charter"));
// Aircraft
const Aircrafts = React.lazy(() => import("views/Aircrafts/Aircrafts"));
const Aircraft = React.lazy(() => import("views/Aircrafts/Aircraft"));
const NewAircraft = React.lazy(() => import("views/Aircrafts/NewAircraft"));

// Requests
const Requests = React.lazy(() => import("views/Requests/RequestContainer"));
const SystemRequests = React.lazy(() =>
  import("views/Requests/SystemRequests")
);

const RequestDisplay = React.lazy(() =>
  import("views/Requests/RequestDisplay")
);

const SystemRequestDisplay = React.lazy(() =>
  import("views/Requests/SystemRequestDisplay")
);

// System Admin Quote
const Quote = React.lazy(() => import("views/Quote/Quote"));
const PaymentLink = React.lazy(() => import("views/PaymentLink/PaymentLink"));

const QuoteDisplay = React.lazy(() => import("views/Quote/QuoteDisplay"));
const SystemQuoteDisplay = React.lazy(() =>
  import("views/Quote/SystemQuoteDisplay")
);

const Profile = React.lazy(() => import("views/Profile/Profile"));

const Setting = React.lazy(() => import("views/Setting/Setting"));
const FlightRecord = React.lazy(() =>
  import("views/FlightRecord/FlightRecord")
);

const Notifications = React.lazy(() =>
  import("views/NotificationPage/NotificationsContainer")
);

const UploadData = React.lazy(() => import("views/AdminUpload/UploadData"));

const ChatList = React.lazy(() => import("views/Chat/ChatList/ChatList"));

const Legal = React.lazy(() => import("views/Legal/Legal"));
// Entity
const entityPrefix = "/entity";

const systemPrefix = "/system";

const DASHBOARD_ROUTE = `${entityPrefix}/dashboard`;
const CALENDAR_ROUTE = `${entityPrefix}/calendar`;
const STUDENTS_ROUTE = `${entityPrefix}/students`;
const ORDERS_ROUTE = `${entityPrefix}/orders`;
const SERVICES_ROUTE = `${entityPrefix}/services`;
const NEW_SERVICE_ROUTE = `${entityPrefix}/services/newService`;
const SETTING_ROUTE = `${entityPrefix}/setting`;
const FLIGHT_RECORD_ROUTE = `${entityPrefix}/flight-record`;
const PILOT_ROUTE = `${entityPrefix}/pilot`;

const AIRCRAFTS_ROUTE = `${entityPrefix}/aircrafts`;
const AIRCRAFT_ROUTE = `${entityPrefix}/aircrafts/:resourceId`;
const NEW_AIRCRAFT_ROUTE = `${entityPrefix}/new-aircraft`;

const REQUESTS_ROUTE = `${entityPrefix}/requests`;
const REQUEST_ROUTE = `${entityPrefix}/requests/:requestId`;
const REQUEST_QUOTE_ROUTE = `${entityPrefix}/requests/:requestId/quotes/:quoteId`;

const QUOTE_ROUTE = `${entityPrefix}/quotes/:quoteId`;

const PROFILE_ROUTE = `${entityPrefix}/profile`;
const NOTIFICATIONS_ROUTE = `${entityPrefix}/notifications`;

const LEGAL_ROUTE = `${entityPrefix}/legal`;
const CHAT_LIST_ROUTE = `${entityPrefix}/chat-list`;

const CHARTERS_ROUTE = `${entityPrefix}/charters`;
const CHARTER_ROUTE = `${entityPrefix}/charters/:charterId`;
const NEW_CHARTER_ROUTE = `${entityPrefix}/new-charter`;

const SYSTEM_CHAT_LIST_ROUTE = `${systemPrefix}/chat-list`;

const SYSTEM_REQUESTS_ROUTE = `${systemPrefix}/requests`;
const SYSTEM_REQUEST_ROUTE = `${systemPrefix}/requests/:requestId`;
const SYSTEM_REQUEST_QUOTE_ROUTE = `${systemPrefix}/requests/:requestId/quotes/:quoteId`;
const SYSTEM_PAYMENT_LINK = `${systemPrefix}/payment-link`;

const SYSTEM_BATCH_DATA = `${systemPrefix}/batch-data`;

export const routeObject = {
  entity: {
    DASHBOARD_ROUTE,
    CALENDAR_ROUTE,
    STUDENTS_ROUTE,
    ORDERS_ROUTE,
    SERVICES_ROUTE,
    NEW_SERVICE_ROUTE,
    AIRCRAFTS_ROUTE,
    AIRCRAFT_ROUTE,
    NEW_AIRCRAFT_ROUTE,
    SETTING_ROUTE,
    FLIGHT_RECORD_ROUTE,
    PILOT_ROUTE,
    REQUESTS_ROUTE,
    REQUEST_ROUTE,
    REQUEST_QUOTE_ROUTE,
    QUOTE_ROUTE,
    PROFILE_ROUTE,
    NOTIFICATIONS_ROUTE,
    LEGAL_ROUTE,
    CHAT_LIST_ROUTE,
    CHARTERS_ROUTE,
    NEW_CHARTER_ROUTE,
    CHARTER_ROUTE,
  },
  system: {
    SYSTEM_CHAT_LIST_ROUTE,
    SYSTEM_REQUESTS_ROUTE,
    SYSTEM_REQUEST_ROUTE,
    SYSTEM_REQUEST_QUOTE_ROUTE,
    SYSTEM_BATCH_DATA,
  },
};

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: DASHBOARD_ROUTE, name: "Dashboard", component: Dashboard },

  { path: PROFILE_ROUTE, name: "Profile", component: Profile },

  { path: CALENDAR_ROUTE, name: "Calendar", component: Schedule },

  { path: STUDENTS_ROUTE, name: "Students", component: Students },

  { path: ORDERS_ROUTE, name: "Orders", component: Orders },
  {
    path: SERVICES_ROUTE,
    exact: true,
    name: "Services",
    component: Services,
  },
  {
    path: NEW_SERVICE_ROUTE,
    name: "New Service",
    component: NewService,
  },
  {
    path: AIRCRAFTS_ROUTE,
    exact: true,
    name: "Aircrafts",
    component: Aircrafts,
  },
  {
    path: AIRCRAFT_ROUTE,
    exact: true,
    name: "Aircraft",
    component: Aircraft,
  },

  {
    path: NEW_AIRCRAFT_ROUTE,
    name: "New Aircraft",
    component: NewAircraft,
  },

  { path: SETTING_ROUTE, name: "Setting", component: Setting },
  {
    path: FLIGHT_RECORD_ROUTE,
    name: "FlightRecord",
    component: FlightRecord,
  },
  { path: PILOT_ROUTE, name: "Pilot", component: Pilot },

  { path: "/system/quote-contract", name: "Quote", component: Quote },
  { path: SYSTEM_PAYMENT_LINK, name: "PaymentLink", component: PaymentLink },

  {
    path: [REQUESTS_ROUTE],
    exact: true,
    name: "Requests",
    component: Requests,
  },
  {
    path: [SYSTEM_REQUESTS_ROUTE],
    exact: true,
    name: "Requests",
    component: SystemRequests,
  },
  {
    path: [REQUEST_ROUTE],
    exact: true,
    name: "Request Details",
    component: RequestDisplay,
  },
  {
    path: [SYSTEM_REQUEST_ROUTE],
    exact: true,
    name: "Request Details",
    component: SystemRequestDisplay,
  },
  {
    path: [SYSTEM_BATCH_DATA],
    exact: true,
    name: "Batch Data",
    component: UploadData,
  },
  {
    path: [REQUEST_QUOTE_ROUTE, QUOTE_ROUTE],
    exact: true,
    name: "Quote Details",
    component: QuoteDisplay,
  },
  {
    path: [SYSTEM_REQUEST_QUOTE_ROUTE],
    exact: true,
    name: "Quote Details",
    component: SystemQuoteDisplay,
  },
  {
    path: NOTIFICATIONS_ROUTE,
    exact: true,
    name: "Notifications",
    component: Notifications,
  },
  {
    path: [CHAT_LIST_ROUTE, SYSTEM_CHAT_LIST_ROUTE],
    name: "Chat List",
    component: ChatList,
  },

  {
    path: LEGAL_ROUTE,
    name: "Legal",
    component: Legal,
  },

  {
    path: CHARTERS_ROUTE,
    exact: true,
    name: "Charters",
    component: Charters,
  },
  {
    path: CHARTER_ROUTE,
    exact: true,
    name: "Charter Detail",
    component: Charter,
  },
  {
    path: NEW_CHARTER_ROUTE,
    name: "New Charter",
    component: NewCharter,
  },
];
export default routes;
