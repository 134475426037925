export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const UPDATE_WEATHER_ALERT = "UPDATE_WEATHER_ALERT";
export const UPDATE_HOURS_OF_OPERATION = "UPDATE_HOURS_OF_OPERATION";
export const GET_ENTITY_MANAGERS = "GET_ENTITY_MANAGERS";
export const REMOVE_ENTITY_MANAGERS = "REMOVE_ENTITY_MANAGERS";
export const ADD_ENTITY_MANAGERS = "ADD_ENTITY_MANAGERS";
export const ENTER_BUSINESS_DETAILS = "ENTER_BUSINESS_DETAILS";

export const CHANGE_ENTITY_IMAGE = "CHANGE_ENTITY_IMAGE";

export const GET_ENTITY_TASKS = "GET_ENTITY_TASKS";
