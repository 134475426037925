import {
  DELETE_RESOURCE,
  CREATE_RESOURCE,
  EDIT_RESOURCE,
} from "../constants/resourceConstant";
import { LOAD_INITIAL } from "../constants/globalConstant";

const initialState = {
  resources: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL:
      return {
        ...state,
        resources: Array.isArray(action.payload.resources)
          ? action.payload.resources.reduce((acc, resource) => {
              acc[resource._id] = resource;
              return acc;
            }, {})
          : action.payload,
      };
    case DELETE_RESOURCE:
      const {
        [action.payload.resource._id]: deleted,
        ...rest
      } = state.resources;
      return {
        ...state,
        resources: { ...rest },
      };
    case CREATE_RESOURCE:
      return {
        ...state,
        resources: {
          [action.payload.resource._id]: { ...action.payload.resource },
          ...state.resources,
        },
      };
    case EDIT_RESOURCE:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.payload.resource._id]: { ...action.payload.resource },
        },
      };
    default:
      return state;
  }
};
