import { STOP_ACTION, START_ACTION } from "store/constants/uiConstant";

const initialState = {
  loader: {
    actions: [],
  },
};

const alertReducer = (state = initialState, { type, payload }) => {
  const { loader } = state;
  const { actions } = loader;
  switch (type) {
    case START_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: [...actions, payload.action],
        },
      };
    case STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter((action) => action.name !== payload.name),
        },
      };
    default:
      return state;
  }
};

export default alertReducer;
