export const ARGUS_GOLD = "Gold";
export const ARGUS_GOLD_PLUS = "Gold Plus";
export const ARGUS_PLATINUM = "Platinum";
export const WYVERN_WINGMAN = "Wingman";
export const REGISTERED = "Registered";

export const entityRating = {
  argus: {
    [ARGUS_GOLD]: "assets/img/charter_rating/argus/ArgusGoldPlus.png",
    [ARGUS_GOLD_PLUS]: "assets/img/charter_rating/argus/Gold.png",
    [ARGUS_PLATINUM]: "assets/img/charter_rating/argus/Platinum.png",
  },
  wyvern: {
    [WYVERN_WINGMAN]: "assets/img/charter_rating/wyvern/wingman.png",
    [REGISTERED]: "",
  },
};
